<template>
  <div v-if="!isOrderSuccess" class="app-business-wrapper">
    <my-nav class="app-message" title=" 完善APP"></my-nav>
    <div class="examination-object mt25">
      <span class="examination-nav">考籍专业</span>
      <div class="examination-item" v-if="dataList.appList && dataList.appList.length">
        {{ dataList.appList[0].name }}
      </div>
    </div>
    <div class="pirce-object mt20">
      <van-form>
        <van-field readonly required input-align="right" label="开通类型">
          <template #input>
            <div>
              <my-status-list
                :statusList="courseList"
                @getStatusList="choseStatus"
              ></my-status-list>
            </div>
          </template>
        </van-field>
        <div v-if="currentChooseStatus === 2">
          <van-field
            v-model="state.priceName"
            label="价格体系"
            @click="isShowPriceSystem = true"
            right-icon="arrow"
            input-align="right"
            clickable
            readonly
            required
            placeholder="请选择"
          />
          <van-field
            label="添加课程"
            @click="addCourseList"
            input-align="right"
            right-icon="arrow"
            :border="false"
            readonly
            clickable
            required
            placeholder="去调整"
          />
          <div class="project-lists">
            <div class="project-item" v-for="item in getCheckedList" :key="item.goodsNo">
              <p class="porject-title">{{ `${item.goodsName} - ${item.goodsNo}` }}</p>
              <div class="other-message">
                <span>{{ item.applyType === 0 ? "省考" : "统考" }}</span>
                <span class="type"
                  >{{
                    item.consumeType === 1
                      ? "购买"
                      : item.consumeType === 2
                      ? "升级"
                      : "续费"
                  }}·{{ item.signLevel === 1 ? "智慧学" : "精品学" }}</span
                >
                <span>¥{{ item.money }}</span>
              </div>
            </div>
          </div>
          <div v-if="getCheckedList.length" class="price-total">
            <span>价格合计</span>
            <span>¥{{ totalPrice }}</span>
          </div>
        </div>
        <div v-else-if="currentChooseStatus === 1">
          <van-field
            v-model="state.priceName"
            label="价格体系"
            @click="isShowPriceSystem = true"
            right-icon="arrow"
            input-align="right"
            clickable
            readonly
            required
            placeholder="请选择"
          />
          <van-field label="等级" input-align="right" readonly required>
            <template #input>
              <div>
                <my-status-list
                  :statusList="levelList2"
                  @getStatusList="getlevelType2"
                  :isDisabled="totalExaminationDisabled"
                  @disabledTip="disabledTip"
                ></my-status-list>
              </div>
            </template>
          </van-field>
          <!-- 自考本科且整专业时选择报考类型 -->
          <!-- {{dataList}} -->
          <van-field v-if="dataList.signTypeValue == 2" label="报考类型" input-align="right" readonly required>
            <template #input>
              <div>
                <my-status-list
                  :statusList="registerTypeList2"
                  @getStatusList="getRegisterType"
                ></my-status-list>
              </div>
            </template>
          </van-field>
          <van-field
            v-if="state.consumeType === 3"
            v-model="renewYearObj.renewYearMess"
            label="续费年份"
            @click="isRenewYear = true"
            right-icon="arrow"
            input-align="right"
            clickable
            readonly
            required
            placeholder="请选择"
          />
          <van-field
            v-model="state.totalMoney"
            input-align="right"
            readonly
            label="价格"
          />
          <van-field :border="false" readonly input-align="right" label="消费类型">
            <template #input>
              <div class="otherTypes">
                {{
                  state.consumeType == 1
                    ? "购买"
                    : state.consumeType == 2
                    ? "升级"
                    : state.consumeType == 3
                    ? "续费"
                    : ""
                }}
              </div>
            </template>
          </van-field>
        </div>
      </van-form>
    </div>
    <my-nav class="app-message" title=" 其他信息"></my-nav>
    <div class="pirce-object mt20">
      <my-audit-form
        ref="auditForm"
        :currentType="2"
        @hasDisabled="getAuditStatus"
        :maxMoney="currentChooseStatus === 1 ? state.totalMoney : totalPrice"
        :disabled="
          currentChooseStatus === 1
            ? state.totalMoney
              ? false
              : true
            : totalPrice
            ? false
            : true
        "
      ></my-audit-form>
    </div>
    <div class="other-actions-sheet" v-if="isShowPriceSystem">
      <van-action-sheet
        :show="isShowPriceSystem"
        title="价格体系"
        :actions="currentPriceSystemLists"
        @select="getPriceSystem"
        @cancel="isShowPriceSystem = false"
      />
    </div>
    <div class="other-actions-sheet" v-if="isRenewYear">
      <van-action-sheet
        :show="isRenewYear"
        title="续费年份"
        :actions="renewYearList"
        @select="getRenwYear"
        @cancel="isRenewYear = false"
      />
    </div>
    <div class="other-actions-sheet" v-if="isShowChooseCourse">
      <van-action-sheet
        :show="isShowChooseCourse"
        title="课程调整"
        @select="getPriceSystem"
        @cancel="isShowChooseCourse = false"
      >
        <div class="content">
          <div class="notice_info">
            <van-icon
              name="info"
              color="#1678FF"
              class="mr16"
            />请选择下面课程中的考试类型和等级。
          </div>
          <div class="course-item" v-for="item in getAppLists" :key="item.type">
            <p class="course-title">{{ item.goodsName }}</p>
            <div class="course-status">
              <div class="course-status-item" v-if="dataList.signTypeValue === 2">
                <my-status-list
                  :statusList="examTypeList"
                  @getStatusList="getExamType($event, item)"
                  :checkedData="item.applyType"
                ></my-status-list>
              </div>
              <div v-if="dataList.signTypeValue === 2" class="line"></div>
              <div class="course-status-item">
                <my-status-list
                  :statusList="levelList2"
                  :checkedData="item.signLevel"
                  @getStatusList="getLevel($event, item)"
                ></my-status-list>
              </div>
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>
    <div class="footer-wrapper">
      <my-footer
        :successSubmitRight="isFailed"
        :isShowLeft="false"
        :loadingRight="loading"
        :rightText="currentButtonName"
        @handleButtonRight="submitRight"
      ></my-footer>
    </div>
  </div>
  <my-pay-order
    v-else-if="payDataList.sn && isOrderSuccess"
    :dataList="payDataList"
    @closePay="closePay"
  ></my-pay-order>
</template>
<script>
import myStatusList from "@/components/statusList";
import {
  payLists,
  examTypeList,
  levelList2,
  courseList,
  renewYearList,
  registerTypeList2
} from "@/utils/const";
import { computed, reactive, ref, toRefs, watch } from "vue";
import { CellGroup, Cell, Form, Field, ActionSheet, Toast, Icon } from "vant";
import myPayOrder from "@/components/payorder";
import myNav from "@/components/nav";
import { getAppMajorAllCourse } from "@/api";
import myAuditForm from "@/components/discountsAudit/auditForm";
import {
  getPriceSystemList,
  checkBuyAppGoods,
  businessOrderGoods,
  freeSn,
} from "@/api/common";
import { useRoute, useRouter } from "vue-router";
import { getCustomerBusinessById } from "@/api";
import myFooter from "@/components/buttonGroup";
import { checkSnPays } from "@/utils/check";
import { useStore } from "vuex";
export default {
  name: "OpenApp",
  props: {
    idCard: {
      type: String,
      default: "",
    },
  },
  components: {
    myStatusList,
    myNav,
    myAuditForm,
    myPayOrder,
    myFooter,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Form.name]: Form,
    [Field.name]: Field,
    [ActionSheet.name]: ActionSheet,
    [Icon.name]: Icon,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    let state = reactive({});
    const currentPriceSystemLists = ref([]);
    const isOrderSuccess = ref(false);
    let getAppList = reactive({
      getAppLists: [],
    });
    const showModel = reactive({
      isShowPriceSystem: false,
      isShowChooseCourse: false,
      isRenewYear: false,
    });
    const totalExaminationDisabled = ref(true);
    const loading = ref(false);
    const auditForm = ref(null);
    // 当前商机Id
    const businessId = route.query.businessId;
    // 身份证号码
    const idCard = route.query.idCard;
    // 整专业商品数组
    const allToatlList = ref([]);
    // 续费年份
    const renewYearObj = reactive({
      renewYearNum: 1,
      renewYearMess: "1年",
    });
    let currentProfessinalList = reactive({});
    // let totalGoodsList = reactive({});
    let currentChooseStatus = ref("");
    const isFailed = ref(true);
    const changeParams = reactive({
      currentAuditStatus: false, // 当前优惠列表必填字段
    });
    let currentDetails = reactive({
      dataList: {},
      currentButtonName: "确认开通",
    });
    const getPayList = reactive({
      payDataList: {},
    }); // 支付订单详细信息
    const getAppMajorAllCourseList = (id) => {
      getAppMajorAllCourse({
        majorNo: id,
      }).then((res) => {
        if (res && res.body) {
          getAppList.getAppLists = res.body.map((item) => {
            return {
              goodsNo: item.id,
              goodsName: item.name,
              applyType: "-1",
              signLevel: "-1",
            };
          });
        }
      });
    };
    // 获取续费年份
    const getRenwYear = (val) => {
      renewYearObj.renewYearMess = val.name;
      renewYearObj.renewYearNum = val.type;
      showModel.isRenewYear = false;
    };
    const totalPrice = computed(() => {
      return getCheckedList.value.reduce((total, item) => {
        return total + item.money;
      }, 0);
    });
    const getCheckedList = computed(() => {
      return getAppList.getAppLists.filter((item) => {
        return item.applyType !== "-1" && item.signLevel !== "-1";
      });
    });
    // 改变订单类型
    const currentOrderType = computed(() => {
      return store.state.client.currentDiscountsType;
    });
    // 选择整专业还是单门课程
    const choseStatus = (item) => {
      currentProfessinalList = {}; // 清除专业数据
      restAppList(); // 清空专业数据
      state.priceName = "";
      state.totalMoney = "";
      state.consumeType = "";
      totalExaminationDisabled.value = true;
      isFailed.value = true // 按钮禁用
      currentChooseStatus.value = item.type; // 1整专业 2 单门课程
      getPriceSystemLists(); // 获取价格详情
      // auditForm.value.clearForm();
    };
    const restAppList = () => {
      getAppList.getAppLists.forEach((item) => {
        item.applyType = "-1";
        item.signLevel = "-1";
      });
    };
    // 获取商机详情
    const getBusinessDetails = () => {
      getCustomerBusinessById(businessId).then((res) => {
        if (res && res.body) {
          currentDetails.dataList = res.body;
          getAppMajorAllCourseList(res.body.appList[0].id);
        }
      });
    };
    getBusinessDetails();
    const checkArrHas = (goodsNo) => {
      // 验证是否有这个商品
      let currentIndex;
      currentIndex = getAppList.getAppLists.findIndex((item) => item.goodsNo === goodsNo);
      return currentIndex;
    };
    const getExamType = (item, current) => {
      let indexs = checkArrHas(current.goodsNo);
      let currentObj = getAppList.getAppLists[indexs];
      if (item.type === currentObj.applyType) {
        // 再次点击取消选择
        currentObj.applyType = "-1";
      } else {
        currentObj.applyType = item.type;
        if (currentObj.signLevel !== "-1") {
          checkBuyAppGood(currentObj, indexs, "applyType");
        }
      }
    };
    const getLevel = (item, current) => {
      let indexs = checkArrHas(current.goodsNo);
      let currentObj = getAppList.getAppLists[indexs];
      if (item.type === currentObj.signLevel) {
        currentObj.signLevel = "-1";
      } else {
        currentObj.signLevel = item.type;
        if(currentDetails.dataList.signTypeValue == 2) { // 只有自考本科时才有省考统考
          if (currentObj.applyType !== "-1") {
            checkBuyAppGood(currentObj, indexs, "signLevel");
          }
        } else {
          if(currentObj.applyType) {
            delete currentObj.applyType
          }
          checkBuyAppGood(currentObj, indexs, "signLevel");
        }
      }
    };
    // 点击课程调整时，身份证和价格体系不能为空
    const addCourseList = () => {
      if (idCard && state.priceId && state.priceId && state.priceName) {
        showModel.isShowChooseCourse = true;
      } else {
        Toast({
          message: "请先选择价格体系！",
        });
      }
    };

    // 开通app
    const submitRight = () => {
      loading.value = true;
      const params = currrentSingular();
      params.order = true;
      params.isBusinessAppOrder = 2;
      params.businessId = businessId;
      businessOrderGoods(params).then((res) => {
        if (res && res.body) {
          if (currentOrderType.value === 2) {
            const sn = res.body.sn;
            checkSnPays(
              () => {
                if (res.body.money) {
                  // let signType = "";
                  // if (currentChooseStatus.value === 1) {
                  //   // 整专业 后面跟上是精品班还是智慧班
                  //   signType =
                  //     currentProfessinalList.signLevel === 3 ? "精品班" : "智慧班";
                  // }
                  // 返回有金额就去支付
                  getPayList.payDataList.totalPrice = res.body.money;
                  // getPayList.payDataList.title = `${
                  //   currentDetails.dataList.signTypeName
                  // } · ${currentDetails.dataList.description} ${
                  //   signType ? `·${signType}` : ""
                  // } `;
                  getCheckedList.value.forEach(item => {
                    item.name = item.goodsName
                  })
                  if(currentChooseStatus.value === 1) {
                    allToatlList.value.forEach(item => {
                      item.name = item.goodsName
                    })
                  }
                  console.log(getCheckedList.value)
                  getPayList.payDataList.orderDiscount = 2 // 不是优惠订单
                  getPayList.payDataList.checkSuccessList = currentChooseStatus.value === 1 ? allToatlList.value :  getCheckedList.value;
                  getPayList.payDataList.sn = res.body.sn;
                  getPayList.payDataList.currentType = currentChooseStatus.value == 1 ? 2 : 1;
                  console.log(getPayList.payDataList)
                  loading.value = false;
                  isOrderSuccess.value = true; // 转跳支付
                } else {
                  // 没有金额就转跳
                  freeSn({
                    sn: sn,
                  }).then((res) => {
                    if (res && res.body) {
                      router.push({
                        path: "/paySuccess",
                        query: {
                          type: 2,
                          title: "免费开通",
                        },
                      });
                    }
                  });
                }
              },
              res.body.sn,
              () => {
                // 不能支付弹窗关闭回调
                loading.value = false;
              }
            );
          } else {
            // 是优惠订单
            let titleName = "";
            if (currentChooseStatus.value === 1) {
              titleName = `${currentDetails.dataList.signTypeName} · ${currentDetails.dataList.appList[0].name}·整专业`;
            } else {
              const courseName = getCheckedList.value
                .map((item) => item.goodsName)
                .join("、");
              titleName = `${currentDetails.dataList.signTypeName} · ${currentDetails.dataList.appList[0].name}· 单门课程·【${courseName}】 `;
            }
            router.push({
              path: "/paySuccess",
              query: {
                type: 4,
                aduitPerson: store.state.client.currentAuditMessage.name,
                title: titleName,
              },
            });
          }
        }
      });
    };
    const checkBuyAppGood = (currentItem, currentIndex, type) => {
      if (currentChooseStatus.value === 2) {
        currentItem.signType = 1;
      }
      const dataList = {
        idCard: idCard,
        orderType: 1,
        priceSystemId: state.priceId, // 价格体系ID
        priceSystemName: state.priceName, // 价格体系名称
        goodsList: [currentItem],
      };
      checkBuyAppGoods(dataList).then((res) => {
        if (res && res.body) {
          if (currentChooseStatus.value == 2) {
            // 当前为单门课程
            if (!res.body.order) {
              getAppList.getAppLists[currentIndex][type] = "-1";
              Toast({
                message: res.body.goodsList[0].reason,
              });
            } else {
              // res.body.goodsList[0].name = getAppList.getAppLists[currentIndex].name;
              getAppList.getAppLists[currentIndex] = res.body.goodsList[0];
            }
          } else {
            // 整专业
            if (!res.body.order) {
              Toast({
                message: res.body.goodsList[0].reason,
              });
              allToatlList.value = [];
              state.totalMoney = "";
              state.consumeType = "";
            } else {
              allToatlList.value = res.body.goodsList;
              state.totalMoney = res.body.goodsList[0].money;
              state.consumeType = res.body.goodsList[0].consumeType;
            }
          }
        }
      });
    };
    const getAuditStatus = (flag) => {
      changeParams.currentAuditStatus = flag;
    };
    // 监听订单类型改变改变按钮内容
    watch(currentOrderType, (val) => {
      if (val === 2) {
        currentDetails.currentButtonName = "确认开通";
      } else {
        currentDetails.currentButtonName = "生成订单并提交优惠审批";
      }
    });
    // 监听验证成功是否有值，控制按钮是否可以点击
    watch(allToatlList, (val) => {
      console.log(val)
      if (val.length) {
        if (changeParams.currentAuditStatus) {
          isFailed.value = false;
        } else {
          isFailed.value = true;
        }
      } else {
        isFailed.value = true;
      }
    });
    watch(
      changeParams,
      (val) => {
        if (val.currentAuditStatus) {
          isFailed.value = false;
        } else {
          isFailed.value = true;
        }
      },
      { deep: true }
    );
    watch(currentOrderType, (val) => {
      if (val === 2) {
        if (getCheckedList.length) {
          isFailed.value = false;
        } else {
          isFailed.value = true;
        }
      } else {
        if (getCheckedList.length && changeParams.currentAuditStatus) {
          isFailed.value = false;
        } else {
          isFailed.value = true;
        }
      }
    });
    watch(getCheckedList, (val) => {
      if (val.length) {
        if (currentOrderType.value === 2) {
          isFailed.value = false;
        } else {
          if (changeParams.currentAuditStatus) {
            isFailed.value = false;
          } else {
            isFailed.value = true;
          }
        }
      } else {
        isFailed.value = true;
      }
    });
    // 获取价格价格体系
    const getPriceSystemLists = () => {
      getPriceSystemList({
        type: currentChooseStatus.value, // 1是专业 2 是课程
      }).then((res) => {
        if (res && res.body) {
          currentPriceSystemLists.value = res.body.map((item) => {
            return {
              name: item.name,
              type: item.id,
            };
          });
        }
      });
    };
    // 返回课程参数值
    const currrentSingular = () => {
      const auditFormParams = auditForm.value.returnCurrentParams();
      let currentParams = {
        idCard: idCard,
        orderType: 1,
        priceSystemId: state.priceId,
        priceSystemName: state.priceName,
        ...auditFormParams,
      };
      if (currentChooseStatus.value == 2) {
        currentParams.goodsList = getCheckedList.value;
      } else {
        if (allToatlList.value[0].consumeType === 3) {
          // 整专业 且返回类型为续费
          allToatlList.value[0].majorGoodsOverdueTime = renewYearObj.renewYearNum;
        }
        currentParams.goodsList = allToatlList.value;
      }
      return currentParams;
    };
    const getlevelType2 = (item) => {
      if (totalExaminationDisabled.value === true) return false;
      currentProfessinalList.goodsName = currentDetails.dataList.appList[0].name
      currentProfessinalList.goodsNo = currentDetails.dataList.appList[0].id
      currentProfessinalList.signLevel = item.type
      currentProfessinalList.signType = currentDetails.dataList.signTypeValue
      console.log(currentProfessinalList)
      if(currentDetails.dataList.signTypeValue == 2 ) { // 自考本科且整专业时验证报考类型是否选择
        if(currentProfessinalList.applyType == 0 || currentProfessinalList.applyType == 1) {
           checkBuyAppGood(currentProfessinalList);
        }
      } else {
        checkBuyAppGood(currentProfessinalList);
      }
      
    };
    const disabledTip = () => {
      Toast({
        message: "请先选择价格体系!",
      });
    };
    // 当前选择价格体系
    const getPriceSystem = (item) => {
      state.priceName = item.name;
      state.priceId = item.type;
      state.totalMoney = "";
      state.consumeType = "";
      totalExaminationDisabled.value = false; // 选择了价格体系才能点击
      showModel.isShowPriceSystem = false;
      if (currentProfessinalList.signLevel && currentChooseStatus.value === 1) {
        console.log(currentProfessinalList)
        if(currentDetails.dataList.signTypeValue == 2) { // 是自考本科时验证报考类型
          if(currentProfessinalList.applyType == 0 || currentProfessinalList.applyType == 1) {
            checkBuyAppGood(currentProfessinalList);
          }
        } else {
          checkBuyAppGood(currentProfessinalList);
        }
      }
    };
    // 选择报考类型
    const getRegisterType = (item) => {
      currentProfessinalList.applyType = item.type == 2 ? 0 : item.type
      if(currentProfessinalList.signLevel) {
        checkBuyAppGood(currentProfessinalList);
      }
    }
    const closePay = () => {
      router.push({
        path: "/client",
      });
    };
    return {
      payLists,
      examTypeList,
      levelList2,
      totalExaminationDisabled,
      disabledTip,
      state,
      auditForm,
      getRenwYear,
      renewYearList,
      courseList,
      currentPriceSystemLists,
      isFailed,
      loading,
      totalPrice,
      renewYearObj,
      currentChooseStatus,
      registerTypeList2,
      isOrderSuccess,
      addCourseList,
      getCheckedList,
      getlevelType2,
      getPriceSystem,
      getExamType,
      getLevel,
      choseStatus,
      closePay,
      getRegisterType,
      getAuditStatus,
      currrentSingular,
      submitRight,
      ...toRefs(getPayList),
      ...toRefs(getAppList),
      ...toRefs(currentDetails),
      ...toRefs(showModel),
    };
  },
};
</script>
<style lang="less" scoped>
.app-business-wrapper {
  background: #f5f5f5;
  min-height: 100%;
  padding: 40px 24px 100px 24px;
  box-sizing: border-box;
  // .app-message {
  //   margin-top: 48px;
  // }
  .examination-object {
    background: #fff;
    border-radius: 24px;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .examination-nav,
    .examination-item {
      font-size: 28px;
      color: #333;
    }
    .examination-item {
      max-width: 60vw;
    }
  }
  .pirce-object {
    padding: 32px;
    margin-bottom: 60px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 24px;
    font-size: 28px;
    color: #333;
    .price-total {
      margin-top: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .otherTypes {
      font-size: 28px;
    }
    .project-lists {
      .project-item {
        background: #fafafa;
        border-radius: 12px;
        padding: 24px;
        box-sizing: border-box;
        margin-bottom: 16px;
        .porject-title {
          font-size: 28px;
          font-weight: bold;
          color: #333;
          margin-bottom: 12px;
        }
        .other-message {
          font-size: 26px;
          color: #999;
          & > span:first-child {
            padding-right: 20px;
          }
          & > span:last-child {
            padding-left: 20px;
          }
          .type {
            padding: 0px 20px;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
          }
        }
      }
    }
  }
  .pay-order {
    margin-top: 48px;
  }
  .pay-order-wrapper {
    padding: 32px;
    background: #fff;
    border-radius: 24px;
    box-sizing: border-box;
    .pay-type {
      padding: 32px 0px 16px 0px;
    }
    &:deep(.van-cell:last-child::after) {
      display: inherit !important;
    }
  }
  .content {
    min-height: 40vh;
    .course-item {
      padding-bottom: 35px;
      border-bottom: 1px solid #eee;
      margin-bottom: 35px;
      .course-title {
        color: #333;
        font-size: 30px;
      }
      .course-status {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .line {
          height: 32px;
          padding-right: 20px;
          margin-right: 20px;
          border-right: 1px solid #eee;
        }
      }
    }
  }
  .footer-wrapper {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    background: #fff;
  }
  .notice_info {
    padding: 12px 20px;
    color: #1678ff;
    background: rgba(22, 120, 255, 0.1);
    position: relative;
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-bottom: 16px;
  }
}
</style>
